<template>
  <b-modal
    v-if="selected_entity && selected_entity.context"
    id="make-entity-attr-modal"
    :title="`Make ${selected_entity.context.details.name} an attribute`"
    size="lg"
    class="p-0"
    @show="onShow"
    @ok="makeEntityAttr()"
  >
    <div>
      <b-row>
        <b-col>
          <b-form-group label="Select parent Entity for the Attribute:" label-for="parent-select">
            <b-form-select
              id="parent-select"
              v-model="selected_parent"
              :options="parent_opts"
              :select-size="7"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button variant="primary" @click="ok()">
        Make Attribute
      </b-button>
      <b-button @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import coreService from '@/libs/api-services/core-service'
import { mapState } from 'vuex'

export default {
  name: 'MoveEntityModal',

  data() {
    return {
      selected_parent: false,
    }
  },
  computed: {
    ...mapState({
      selected_entity: state => state.domainModel.selected_entity2,
      components: state => state.domainModel.components,
    }),
    parent_opts() {
      return this.components.filter(cpt => cpt.id !== this.selected_entity.context.details.id).map(x => ({ value: x.id, text: x.qualified_name }))
    },
  },
  methods: {
    onShow() {
      // Get parent of selected component
      this.$store.dispatch('domainModel/getComponents')
      const modelId = this.$store.state.model.id
      coreService.get(`/v1/legacy/domain_model/get_component_parent/${this.selected_entity.context.details.id}`, { params: { model: modelId } })
        .then(response => {
          console.log('Setting selected to ', response.data)
          this.selected_parent = response.data.id
        })
        .catch(r => console.error(r))
    },
    makeEntityAttr() {
      console.debug('Making ', this.selected_entity, ' attr of ', this.selected_parent)
      coreService.post('/v1/legacy/domain_model/convert_entity_to_attribute', {
        component: this.selected_entity.context.details.id,
        new_parent: this.selected_parent,
        model: this.$store.state.model.id,
      })
        .then(() => {
          this.$store.dispatch('domainModel/selectEntity2', this.selected_parent)
            .then(() => {
              this.$emit('attributed', this.selected_parent)
            })
        })
    },
  },
}
</script>

<style scoped>

</style>
