<template>
  <b-modal
    id="performer-report-modal"
    :title="$store.state.model.name + ' Performer Report'"
    no-close-on-backdrop
    size="xl"
    ok-title="Export"
    @show="onShow"
    @ok="$emit('export')"
  >
    <b-spinner v-if="loading" />
    <b-table
      v-else
      class="report-table"
      sticky-header
      fixed
      hover
      no-border-collapse
      :items="performers"
      :fields="performerFields"
      :sort-by.sync="performerSortBy"
      :sort-desc.sync="performerSortDesc"
    >
      <template v-for="(field, field_key) in allocations" v-slot:[`cell(${field})`]="allocation">
        <display-list-group
          v-if="allocation.value.length"
          :key="field_key"
          hide-header
          :items="allocation.value"
        >
          <template v-slot:list-group="{ items }">
            <performer-list-group-item
              v-for="(item, item_key) in items"
              :key="item_key"
              :linked-object="item"
            />
          </template>
        </display-list-group>
        <span v-else :key="field_key" class="text-secondary"> None </span>
      </template>
    </b-table>
  </b-modal>
</template>

<script>

import { ref } from '@vue/composition-api'
import store from '@/store'
import coreService from '@/libs/api-services/core-service'
import DisplayListGroup from '@/views/RequirementsTableLegacy/components/DisplayListGroup.vue'
import PerformerListGroupItem from '@/components/Domain/ReportListGroupItem.vue'

export default {
  name: 'PerformerReport',
  components: { DisplayListGroup, PerformerListGroupItem },
  setup(props, context) {
    const loading = ref(false)
    const allocations = ref(['benefits', 'enablements', 'functions'])
    const performers = ref([])
    const performerFields = ref([
      {
        key: 'name', label: 'Performer/Actor', sortable: true, tdClass: ['font-medium-1', 'font-weight-bold'],
      },
      {
        key: 'benefits', sortable: false, thClass: 'text-center', tdClass: 'text-center',
      },
      {
        key: 'enablements', sortable: false, thClass: 'text-center', tdClass: 'text-center',
      },
      {
        key: 'functions', sortable: false, thClass: 'text-center', tdClass: 'text-center',
      },
    ])
    const performerSortBy = ref('name')
    const performerSortDesc = ref(false)

    const onShow = () => {
      loading.value = true
      performers.value = []
      const params = { model: store.state.model.id }
      coreService.get('/v1/legacy/domain_model/performers_report', { params })
        .then(({ data }) => {
          // convert data: { key: { data }, key: { data } } => [{ name: key, ...data }, { name: key, ...data }]
          performers.value = Object.keys(data).map(key => ({ name: key, ...data[key] }))
          loading.value = false
        })
    }

    return {
      loading,
      allocations,
      performers,
      performerFields,
      performerSortBy,
      performerSortDesc,

      onShow,
    }
  },
}
</script>

<style scoped>

li {
  list-style-type: none;
}

.report-table {
  max-height: 80vh;
}

</style>
