<template>
  <b-modal
    id="add-compliance-modal"
    title="Create Compliance Requirement"
    size="lg"
    @hidden="onClose"
    @show="onShow"
    @ok.prevent="onSubmit"
  >
    <b-form-group
      id="input-comply-with"
      label="Compliance with Standard / Reference"
      label-for="ddl-comply-with"
      description="Select an existing Standard / Reference to comply with or create a new one."
    >
      <b-form-input
        id="datalist-comply-standard"
        v-model="selectedStandard"
        :disabled="isCreating"
        list="standards-list"
      />
      <b-form-datalist id="standards-list">
        <option v-for="std in standards" :key="std.id">
          {{ std.qualified_name }}
        </option>
      </b-form-datalist>
    </b-form-group>

    <b-form-group
      id="input-security-classification"
      label="Security Classification"
      label-for="ddl-security-classification"
      description="Set the security classification for this compliance requirement."
    >
      <b-form-select
        id="ddl-security-classification"
        v-model="selectedSecurityClassification"
        :options="securityClassifications"
        :disabled="isCreating"
        required
      />
    </b-form-group>

    <hr class="mt-2">

    <list-group-requirement
      label="Requirements"
      :entity-array="selectedRequirements"
      modal="associator-generic-requirements-edit"
    />

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Discard
      </b-button>

      <b-button
        variant="success"
        :disabled="isLoading || isCreating || !selectedStandard"
        @click="ok()"
      >
        <span v-if="isCreating">
          <b-spinner small type="grow"/>
          Creating Compliance Requirement...
        </span>
        <span v-else>
          Create Compliance Requirement
        </span>
      </b-button>
    </template>

    <!-- Requirements -->
    <associator-generic
      name="Requirements"
      suffix="-edit"
      :associated-items="selectedRequirementsMapped"
      :all-items="allRequirementsMapped"
      @associated="onRequirementsLinked"
    />
  </b-modal>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import coreService from '@/libs/api-services/core-service'
import ListGroupRequirement from '@/components/Forms/ListGroups/ListGroupRequirement.vue'
import AssociatorGeneric from '@/components/Generic/Associators/AssociatorGeneric.vue'

export default {
  components: {
    ListGroupRequirement,
    AssociatorGeneric,
  },
  setup(props, context) {
    const { modelId } = router.currentRoute.params
    const selectedEntity = computed(() => store.state.domainModel.selected_entity2)
    const standards = computed(() => store.state.domainModel.standards)
    const securityClassifications = computed(() => store.getters['constants/securityClassifications'])
    const defaultSecurityClassification = computed(() => store.state.model.defaultSecurityClassification || store.state.constants.defaultSecurityClassification.id)

    const allRequirements = ref([])
    const selectedRequirements = ref([])
    const allRequirementsMapped = computed(() => allRequirements.value.map(item => ({
      value: {
        id: item.id,
        toSortBy: item.display_id,
      },
      text: `${item.display_id}. ${item.text?.replace(/<\/?[^>]+(>|$)/g, '')}`,
    })))
    const selectedRequirementsMapped = computed(() => selectedRequirements.value.map(item => ({
      value: {
        id: item.id,
        toSortBy: item.display_id,
      },
      text: `${item.display_id}. ${item.text?.replace(/<\/?[^>]+(>|$)/g, '')}`,
    })))
    const onRequirementsLinked = reqs => {
      const temp = findItemById(reqs, allRequirements.value)
      selectedRequirements.value = temp.map(x => ({
        id: x.id,
        display_id: x.display_id,
        object_text: x.text,
        priority: x.priority,
      }))
    }
    const findItemById = (toAssociate, allItems) => {
      // Associators generic spit out arrays of just IDs
      // all link methods need ID and Name
      // this method will find the full object by ID from the all items array
      function sortFunction(value) {
        return allItems.find(item => (item.id === value))
      }

      return toAssociate.map(sortFunction)
    }

    const selectedStandard = ref('')
    const selectedSecurityClassification = ref(defaultSecurityClassification.value)

    const onClose = () => {
      isLoading.value = false
      isCreating.value = false
      selectedStandard.value = ''
      selectedSecurityClassification.value = defaultSecurityClassification.value
      selectedRequirements.value = []
      context.root.$bvModal.hide('add-compliance-modal')
    }

    const isLoading = ref(false)
    const onShow = () => {
      isLoading.value = true
      coreService.get('/v1/legacy/requirements/get_requirements_simple', { params: { model: modelId } })
        .then(response => {
          allRequirements.value = response.data
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    const isCreating = ref(false)
    const onSubmit = () => {
      const selectedEntityId = selectedEntity.value.context.details.id
      isCreating.value = true
      coreService.post(
        '/v1/legacy/domain_model/compliance_requirement',
        {
          parent_component_id: selectedEntityId,
          reference: selectedStandard.value,
          classification: selectedSecurityClassification.value,
          requirements: selectedRequirements.value,
        },
        { headers: { 'Model-Id': modelId } },
      )
        .then(response => {
          // Reload the selected entity
          store.dispatch('domainModel/selectEntity2', selectedEntityId)
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => {
          onClose()
        })
    }

    return {
      selectedEntity,
      isLoading,
      isCreating,

      standards,
      selectedStandard,

      securityClassifications,
      selectedSecurityClassification,

      allRequirements,
      allRequirementsMapped,
      selectedRequirements,
      selectedRequirementsMapped,
      onRequirementsLinked,

      onClose,
      onShow,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-autosuggest.scss';
</style>
