<template>
  <div v-if="selectedCompliance">
    <b-modal
      id="delete-compliance-modal"
      title="Delete Compliance Requirement"
      size="lg"
    >
      <p>
        Are you sure you want to delete the compliance requirement:
        <span class="text-primary font-weight-bolder">{{ selectedCompliance.name }}</span>
        ?
      </p>

      <p class="text-danger font-weight-bolder">
        This action cannot be undone.
      </p>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          No, keep it
        </b-button>

        <b-button variant="danger" @click="onConfirmation">
          Yes, delete it
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import coreService from '@/libs/api-services/core-service'

export default {
  name: 'DeleteComplianceRequirementModal',
  setup(props, context) {
    const selectedEntity = computed(() => store.state.domainModel.selected_entity2)
    const selectedCompliance = computed(() => store.state.domainModel.selected_compliance)

    const onConfirmation = () => {
      const { modelId } = router.currentRoute.params
      coreService
        .delete(
          `/v1/legacy/domain_model/compliance_requirement/${selectedCompliance.value.id}`,
          { headers: { 'Model-Id': modelId } },
        )
        .then(() => { store.dispatch('domainModel/selectEntity2', selectedEntity.value.context.details.id) })
        .catch(error => console.error(error))
        .finally(() => { context.root.$bvModal.hide('delete-compliance-modal') })
    }

    return {
      selectedCompliance,
      onConfirmation,
    }
  },
}
</script>
