<template>
  <div v-if="selected_entity2">
    <h4>{{ name ? 'Interfaces for '+name : 'Interfaces for Entity' }}  <b-badge class="float-right" variant="primary">
      {{ fields.outputs.length + fields.inputs.length }}
    </b-badge> </h4>
    <b-row>
      <b-col>
        <b-card class="mb-1">
          <b-card-body>
            <b-card-text
              v-if="
                fields.outputs.length + fields.inputs.length >
                  0
              "
            >
              <b-list-group>
                <h5 class="mt-3">
                  Outputs
                </h5>
                <b-list-group-item
                  v-for="item in fields.outputs"
                  :key="item.id"
                  @click="showInterface(item.target_props.id)"
                >
                  {{ item.target_props.name }}
                  <i class="fas fa-exchange-alt" />
                </b-list-group-item>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-success"
                  class="float-right"
                  @click="addInterface('output')"
                >
                  Add New Output
                </b-button>

                <h5 class="mt-3">
                  Inputs
                </h5>
                <b-list-group-item
                  v-for="item in fields.inputs"
                  :key="item.id"
                  @click="showInterface(item.target_props.id)"
                >
                  {{ item.target_props.name }}
                  <i class="fas fa-exchange-alt" />
                </b-list-group-item>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-success"
                  class="float-right"
                  @click="addInterface('input')"
                >
                  Add New Input
                </b-button>
              </b-list-group>
            </b-card-text>
            <b-card-text v-else>
              There are no Associated Interfaces
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-success"
                class="float-right"
                @click="addInterface('output')"
              >
                Add New Output
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-success"
                class="float-right"
                @click="addInterface('input')"
              >
                Add New Input
              </b-button>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <hr>

    <b-row>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-info"
          class="float-left"
          @click="routeToInterfaces"
        >
          View/Edit all Interfaces
        </b-button>
      </b-col>
    </b-row>
    <AddInterfaceModal :cpt="id" :direction="direction" @added="postAddInterface" />
  </div>
  <div v-else class="text-center mt-4">
    <b-spinner />
    <b-card>
      <span>Loading...</span>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { BButton, BCol, BRow } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AddInterfaceModal from '@/components/Interfaces/Modals/AddInterfaceModal.vue'
import coreService from '@/libs/api-services/core-service'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    AddInterfaceModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: '',
      name: '',
      acronym: '',
      abstract: false,
      multiplicity: '',
      stereotype_selected: [],
      description: '',
      validity: 'Valid',
      direction: 'output',
      context_data: { attributes: {} },
      allReqs: [],
      fields: {
        inputs: [],
        outputs: [],
      },
      // TODO: Rename back to stereotype_list
      // composition_sterotypes_opts: ['Component', 'Performer', 'System', 'Human Actor', 'Service', 'Function', 'Process',
      //   'Activity', 'Capability', 'Objective', 'Constraint', 'Standard', 'Agreement', 'Reference', 'Objective',
      //   'Measure', 'Resource', 'Information and Data', 'Materiel', 'Skill'],
      quill_editor_options: {
        theme: 'snow',
        modules: {
          toolbar: '#toolbar',
        },
      },
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
  },
  mounted() {
    this.fillFields(this.selected_entity2)
  },
  methods: {
    fillFields(n) {
      const con = n.context
      this.name = con.details.name
      this.acronym = con.details.acronym
      this.description = con.details.description
      this.stereotype_selected = con.labels
      this.abstract = con.details.abstract === true
      this.multiplicity = con.details.multiplicity
      this.validity = con.details.validity
      this.id = con.details.id
      const t = this.fields
      t.inputs = con.relationships.filter(
        e => e.rel_type === 'DESTINATION',
      )
      t.outputs = con.relationships.filter(
        e => e.rel_type === 'SOURCE',
      )
    },
    addInterface(direction) {
      this.direction = direction
      this.$bvModal.show('add-interface-modal')
    },
    postAddInterface(newInterface) {
      this.showInterface(newInterface.id)
    },
    routeToInterfaces(node) {
      this.$router.push({
        name: 'interfaces',
      })
    },
    showInterface(interfaceId) {
      this.$router.push({
        name: 'interfaces',
        query: { focus: interfaceId },
      })
    },
  },
}
</script>
