<template>
  <b-modal
    id="add-attribute-modal"
    title="Create Attribute"
    size="lg"
    @ok="submitFunction"
    @shown="clearForm"
  >
    <form ref="form">
      <b-row>
        <b-col cols="8">
          <b-form-group label="Name" label-for="input-1">
            <b-form-input
              id="input-1"
              v-model="params.name"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group label="Value" label-for="input-v">
            <b-form-input
              id="input-v"
              v-model="params.value"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="4">
          <b-form-group label="Type" label-for="input-2">
            <b-form-select
              id="input-2"
              v-model="params.type"
              :options="type_options"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Unit" label-for="input-3">
            <b-form-input
              id="input-3"
              v-model="params.unit"
            />
          </b-form-group>
        </b-col>

        <b-col style="padding: 32px">

          <b-form-checkbox-group
            v-model="selected"
            :options="options"
          />

        </b-col>
      </b-row>

      <b-form-group label="Validation" label-for="input-4">
        <b-form-textarea
          id="input-4"
          v-model="params.validation"
          rows="3"
          max-rows="6"
        />
      </b-form-group>

      <b-row>

        <b-col>
          <b-form-group label="Minimum" label-for="input-6">
            <b-form-input
              id="input-6"
              v-model="params.min"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Maximum" label-for="input-5">
            <b-form-input
              id="input-5"
              v-model="params.max"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Default Value" label-for="input-7">
            <b-form-input
              id="input-7"
              v-model="params.default"
            />
          </b-form-group>
        </b-col>

      </b-row>
      <b-row>
        <b-col>
          <label for="add_entity_select_2">Classification</label>
          <b-form-select
            id="add_entity_select_2"
            v-model="params.classification"
            :options="securityClassifications"
          />
        </b-col>
      </b-row>
    </form>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Discard
      </b-button>

      <b-button
        variant="success"
        :disabled="loading_status"
        @click="ok()"
      >
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Creating Attribute...
        </span>
        <span v-else>
          Create Attribute
        </span>
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import coreService from '@/libs/api-services/core-service'
import { BCol, BFormGroup, BRow } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
  },
  data() {
    return {
      params: {
        model: this.$store.state.model.id,
        name: '',
        type: 'User Defined',
        unit: '',
        required: false,
        unique: false,
        validation: '',
        min: '',
        max: '',
        value: '',
        default: '',
        classification: '',
      },
      type_options: ['User Defined', 'String', 'Integer', 'Real', 'Date', 'Time', 'Date/Time', 'List', 'Tuple', 'Dictionary'],
      options: ['Required', 'Unique'],
      selected: [],
      loading_status: false,

    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
    ...mapGetters({
      securityClassifications: 'constants/securityClassifications',
    }),
  },
  watch: {
    default(newVal) { this.value = newVal },
    selected(newVal) {
      this.params.required = newVal.includes('Required')
      this.params.unique = newVal.includes('Unique')
    },
  },
  methods: {
    submitFunction(e) {
      e.preventDefault()
      this.loading_status = true
      const selectedId = this.selected_entity2.context.details.id
      console.debug('Add Context: ', selectedId)
      coreService.post(`/v1/legacy/domain_model/add_attribute/${selectedId}`, this.params).then(() => {
        this.loading_status = false
        this.$bvModal.hide('add-attribute-modal')
        this.$store.dispatch('domainModel/selectEntity2', selectedId)
      })
    },
    clearForm() {
      this.params.name = ''
      this.params.type = 'User Defined'
      this.params.unit = ''
      this.params.required = false
      this.params.unique = false
      this.params.validation = ''
      this.params.min = ''
      this.params.max = ''
      this.params.value = ''
      this.params.default = ''
      this.params.classification = this.$store.state.model.defaultSecurityClassification
    },
  },
}
</script>
