<template>
  <b-modal
    id="build-config-picker-modal"
    title="Colour Ontology by Configuration(s)"
    size="lg"
    :ok-title="colouring ? 'Re-drawing the view...' : 'Colour by Configuration(s)'"
    :ok-disabled="colouring || loading"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    lazy
    no-fade
    @show="onShow"
    @ok="onSubmit"
  >
    <p>
      If multiple configurations are selected, it will colour the nodes based on all configurations.
    </p>

    <b-form-group
      label="Select the configuration(s) to colour by"
      label-for="config-select"
    >
      <b-form-select
        id="config-select"
        v-model="selectedBuilds"
        :options="buildOptions"
        :select-size="7"
        :disabled="loading"
        multiple
      >
        <template #first>
          <b-form-select-option v-if="!buildOptions || buildOptions.length < 1" value="" aria-selected="true">
            -- No Configurations --
          </b-form-select-option>
        </template>

      </b-form-select>
    </b-form-group>
  </b-modal>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import coreService from '@/libs/api-services/core-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'BuildConfigurationPickerModal',
  setup(props, context) {
    const { modelId } = router.currentRoute.params

    const loading = ref(false)
    const colouring = ref(false)

    const allBuilds = ref([])
    const buildOptions = computed(() => allBuilds.value.map(b => ({ value: b.id, html: `${b.name} (${b.version})` })))
    const selectedBuilds = ref([])

    const onShow = () => {
      // Reset form to the default values
      colouring.value = false
      selectedBuilds.value = []

      // Get configurations
      loading.value = true
      const params = { model: modelId }
      coreService.get('/v1/legacy/domain_model/get_build_configurations', { params })
        .then(response => { allBuilds.value = response.data })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to colour by configuration',
              text: `${error}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { loading.value = false })
    }
    const onSubmit = () => {
      colouring.value = true

      // Ensure that all selected builds are valid selections (not empty)
      const filtered = selectedBuilds.value.filter(x => x !== '')
      if (filtered.length > 0) {
        context.emit('buildConfigsPicked', filtered)
      }
      colouring.value = false
    }

    return {
      selectedBuilds,
      buildOptions,
      loading,
      colouring,

      onShow,
      onSubmit,
    }
  },
}
</script>
