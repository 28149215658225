<template>
  <b-modal
    id="edit-alias-modal"
    title="Update Alias"
    size="lg"
    @ok="submitFunction"
  >
    <b-form-group label="Alias name" label-for="edit-alias-modal-name">
      <b-form-input
        id="edit-alias-modal-name"
        v-model="name"
        required
        @keydown.enter.native="submitFunction"
      />
    </b-form-group>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="outline-secondary" @click="cancel">
        Cancel
      </b-button>
      <b-button variant="success" @click="ok">
        {{ loading ? 'Updating Alias...' : 'Update Alias' }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import coreService from '@/libs/api-services/core-service'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      loading: false,
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
  },
  watch: {
    data(newVal) {
      this.fillName(newVal.target_props.name)
    },
  },
  mounted() {
    if (this.data.target_props) {
      this.fillName(this.data.target_props.name)
    }
  },
  methods: {
    fillName(val) {
      this.name = val
    },
    submitFunction() {
      this.loading = true
      const selectedId = this.selected_entity2.context.details.id
      const payload = {
        name: this.name,
        model: this.$store.state.model.id,
      }
      coreService
        // Delete old Alias then replace it with a new one, as there is no way to edit (or PUT) to an alias yet
        .delete(`/v1/legacy/domain_model/alias/${this.data.target_props.id}`)
        .then(() => {
          coreService.post(`/v1/legacy/domain_model/add_alias/${selectedId}`, payload)
            .then(() => {
              this.$bvModal.hide('edit-alias-modal')
              this.$store.dispatch('domainModel/invalidateCache')
              this.$store.dispatch('domainModel/selectEntity2', selectedId)
              this.$store.dispatch('domainModel/getComponents')
            })
            .catch(error => {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: 'An error occurred when updating the alias',
                  text: `${error}`,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => { this.loading = false })
        })
    },
  },
}
</script>
