<template>
  <b-modal
    id="delete-instance-modal"
    title="Delete Instance. Are you sure?"
    size="lg"
    :ok-title="loading ? 'Loading...' : 'Yes, delete instance'"
    :ok-disabled="loading"
    ok-variant="danger"
    cancel-title="Keep"
    cancel-variant="outline-secondary"
    lazy
    no-fade
    @show="onShow"
    @ok.prevent="onSubmit"
  >
    <b-alert
      show
      variant="danger"
    >
      <div class="alert-body d-inline-flex justify-content-between">
        <div class="mr-1">
          <feather-icon
            icon="AlertCircleIcon"
            class="text-white"
            size="30"
          />
        </div>
        <div>
          <p>
            You're about to permanently delete the instance:
            <span class="font-weight-bolder text-primary">{{ instance.name }}</span>.
          </p>
          <p>
            To avoid unintended data loss, consider reviewing this action before deleting it.
          </p>
        </div>
      </div>
    </b-alert>
  </b-modal>
</template>

<script>
import coreService from '@/libs/api-services/core-service'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import { BAlert } from 'bootstrap-vue'

export default {
  name: 'InstanceDeleteModal',
  components: {
    BAlert,
  },
  props: {
    instance: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const loading = ref(false)

    const onShow = () => { loading.value = false }
    const onSubmit = () => {
      loading.value = true
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }

      coreService.delete(`/v1/legacy/domain_model/instance/${props.instance.id}`)
        .then(() => {
          context.emit('deleted')
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Instance deleted',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          context.root.$bvModal.hide('delete-instance-modal')
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred when deleting the instance',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      loading,

      onShow,
      onSubmit,
    }
  },
}
</script>
