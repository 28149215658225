import { shapes, util } from '@clientio/rappid';
const markup = [
    {
        tagName: 'rect',
        selector: 'body',
    },
    {
        tagName: 'foreignObject',
        selector: 'bodyText',
        children: [
            {
                tagName: 'div',
                namespaceURI: 'http://www.w3.org/1999/xhtml',
                selector: 'bodyTextContent',
                className: 'ontxt',
            },
        ],
    },
    {
        tagName: 'text',
        selector: 'header',
    },
];
// noinspection JSAnnotator
export class OntologyNodeShape extends shapes.basic.Rect {
    markup = markup;
    defaults() {
        return {
            ...super.defaults,
            type: 'komp.OntologyNodeShape',
            size: { width: 300, height: 125 },
            attrs: {
                body: {
                    width: 300,
                    height: 125,
                    rx: 10,
                    ry: 10,
                    strokeWidth: 2,
                    stroke: 'black',
                    magnet: true,
                },
                header: {
                    textVerticalAnchor: 'middle',
                    textAnchor: 'left',
                    x: 10,
                    y: 20,
                    fontSize: 11,
                    fill: 'black',
                },
                bodyTextContent: {
                    textVerticalAnchor: 'middle',
                    textAnchor: 'middle',
                    x: 150,
                    y: 65,
                    fill: 'black',
                },
                bodyText: {
                    width: 290,
                    height: 110,
                    x: 6,
                    y: 6,
                },
            },
        };
    }
}
Object.assign(shapes, {
    komp: {
        OntologyNodeShape,
    },
});
export function createShape(node, parentNode) {
    const options = {
        position: { x: 0, y: 0 },
        attrs: {
            bodyTextContent: {
                title: '',
                html: '',
            },
            body: {
                class: node.abstract ? 'ont-abstract' : 'ont-node',
                stroke: node.abstract ? 'white' : 'black',
                strokeDasharray: node.abstract ? '4 2' : '1 2',
            },
            header: {
                text: '',
            },
        },
        id: node?.id || Math.random(),
    };
    let shape = null;
    if (node) {
        shape = new OntologyNodeShape(options);
        updateShape(node, shape, parentNode);
    }
    return shape;
}
export function updateShape(node, shape, parentNode) {
    let wrappedHeaderText = '';
    if (!node.labels) {
        console.log('node with no labels', node);
    }
    else {
        const labels = node?.labels
            .filter(l => l !== 'Component' && !l.startsWith('RBAC_'))
            .filter((l, ix) => ix === 0 || !parentNode || !parentNode.labels?.includes(l))
            .filter((l, ix) => ix < 5)
            .join(', ');
        wrappedHeaderText = node ? labels : '';
    }
    const nodeHtml = `<small>${wrappedHeaderText}</small><p>${node?.display_name}</p>`;
    const wrappedBodyText = util.sanitizeHTML(nodeHtml);
    let fontSizeBody = Math.max(32 - (node?.display_name?.length * 0.5), 12);
    const attrs = {
        body: {
            strokeDasharray: node.abstract ? '4 2' : '',
        },
        bodyTextContent: {
            html: wrappedBodyText,
            style: {
                'font-size': `${fontSizeBody}pt`,
            },
        },
    };
    shape.attr(attrs);
    if (node.buildConfig) {
        shape.attr('body/class', shape.attr('body/class') + ` ont-bc-${node.buildConfig}`);
    }
}
export function createHeaderShape(id, text, heading) {
    const nodeHtml = `<small>${heading}</small><p>${text}</p>`;
    const wrappedBodyText = util.sanitizeHTML(nodeHtml);
    const options = {
        position: { x: 0, y: 0 },
        attrs: {
            bodyTextContent: {
                html: nodeHtml,
            },
            body: {
                class: 'ont-header',
                stroke: 'white',
            },
        },
        id: id,
    };
    const shape = new OntologyNodeShape(options);
    shape.resize(300, 125);
    const fontSizeBody = Math.max(32 - (text.length * 0.5), 12);
    shape.attr('header/fontSize', 14);
    const attrs = {
        bodyTextContent: {
            style: {
                'font-size': `${fontSizeBody}pt`,
            },
        },
    };
    shape.attr(attrs);
    return shape;
}
