<template>
  <ValidationObserver ref="validationObserver" v-slot="{ invalid }">
  <b-form v-if="selected_entity2"
    @submit="submitFunction"
    :ok-disabled="invalid"
  >
    <!-- Component Stereotype -->
    <div class="d-inline-flex w-100">
      <p v-if="entityType.length > 0" class="mb-0 mr-1 font-small-3 font-weight-bolder">
        Stereotype{{ entityType.length > 1 ? 's' : '' }}:
      </p>
      <p v-else class="mb-0 mr-1 font-small-3 font-weight-bold text-muted">
        No stereotype
      </p>
      <div
        v-for="(stereotype, index) in entityType"
        :key="index"
        class="mb-50 mr-50"
      >
        <feather-icon
          icon="CircleIcon"
          size="10"
          class="ml-1 mr-50 text-muted"
        />
        <feather-icon
          v-b-tooltip.hover.above="`Component type: ${stereotype}`"
          :icon="entityLabel(stereotype)"
          class="font-small-3 mr-50"
        />
        <span class="font-small-3 font-weight-bolder">{{ stereotype }}</span>
      </div>
    </div>

    <!-- Component Heading -->
    <div class="d-inline-flex w-100 justify-content-between align-items-center">
      <h4
        :title="selected_entity2.context.details.name"
        class="text-primary"
      >
        {{ selected_entity2.context.details.name }}
      </h4>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        title="Upload an image for this component"
        variant="flat-info"
        class="btn-icon"
        @click="openImageModal"
      >
        <feather-icon icon="ImageIcon" size="18" />
      </b-button>
    </div>

    <hr class="my-1">

    <!-- Lineage -->
    <div class="mb-50 mr-50">
      <feather-icon
        icon="GitBranchIcon"
        class="font-small-3 mr-50"
      />
      <span class="font-small-3 font-weight-bolder">
        Lineage:
      </span>
    </div>

    <div v-if="selected_entity2.context.parent_rel" class="w-100">
      <p class="ml-1 mb-0 font-small-3 font-weight-bold">
        Parent
      </p>
      <div class="ml-1 d-inline-flex">
        <p class="font-small-3">
          <span
            :title="selected_entity2.context.details.name"
            class="text-primary font-weight-bold"
          >
            {{ selected_entity2.context.details.name.length > 20 ? 'This component' : selected_entity2.context.details.name }}
          </span>
          <b-dropdown
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :text="selected_entity2.context.parent_rel.rel_type === 'PART_OF' ? 'is a part of' : 'is a type of'"
            variant="flat-success"
            size="sm"
            class="font-small-3 p-0 m-0"
          >
            <b-dropdown-item
              :disabled="selected_entity2.context.parent_rel.rel_type === 'PART_OF'"
              @click="changeRel"
            >
              is a part of
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="selected_entity2.context.parent_rel.rel_type === 'TYPE_OF'"
              @click="changeRel"
            >
              is a type of
            </b-dropdown-item>
          </b-dropdown>
          <span class="text-primary font-weight-bold">
            <router-link
              :to="{ name: 'domain_ontology', query: { focus: selected_entity2.context.parent_rel.parent } }"
              :title="`Navigate to: ${selected_entity2.context.parent_rel.parent_name}`"
            >
              {{ truncateText(selected_entity2.context.parent_rel.parent_name, 100) }}
              <i class="fas fa-external-link-alt" />
            </router-link>
          </span>
        </p>
      </div>
    </div>
    <div v-else>
      <p class="ml-1 mb-0 font-small-3 font-weight-bold">
        Parent
      </p>
      <p class="ml-1 font-small-3 font-weight-bold text-muted">This component has no parent.</p>
    </div>

    <div v-if="findChildren().length > 0">
      <p class="ml-1 mb-0 font-small-3 font-weight-bold">
        Children
      </p>
      <div class="ml-1 d-inline-flex">
        <p class="font-small-4 mb-0">
          <ul style="max-height: 20rem" class="overflow-y-scroll">
            <li v-for="child in findChildren()" :key="child.id">
              <span class="font-small-3 font-weight-bold">
                <router-link
                  :to="{ name: 'domain_ontology', query: { focus: child.id } }"
                  :title="`Navigate to: ${child.name}`"
                >
                  {{ child.name }}
                  <i class="fas fa-external-link-alt" />
                </router-link>
              </span>
            </li>
          </ul>
        </p>
      </div>
    </div>
    <div v-else>
      <p class="ml-1 mb-0 font-small-3 font-weight-bold">
        Children
      </p>
      <p class="ml-1 font-small-3 font-weight-bold text-muted">
        This component has no children.
      </p>
    </div>

    <hr class="mb-2">

    <b-row>
      <b-col cols="8">
        <label for="entityInput1">Entity name</label>
        <validation-provider
          v-slot="{ errors }"
          vid="entity_name"
          name="Entity name"
          rules="required"
        >
        <b-form-input
          id="entityInput1"
          v-model="name"
          size="sm"
          required
          @update="validate"
          @focusout="validate"/>
        <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>

      <b-col>
        <label for="entityInput2">Acronym</label>
        <b-form-input id="entityInput2" v-model="acronym" size="sm" />
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col>
        <label for="entityRich1">Description</label>
        <div v-show="!showDescriptionEditor" @click="editDescription">
          <p v-sanitized-html="description"></p>
          <span v-if="!description" class="text-muted">Click to add description</span>
        </div>
        <tip-tap-editor
          v-show="showDescriptionEditor"
          id="entityRich1"
          v-model="description"
          min-height="8"
          max-height="8"
          :allow-image-upload="false"
        />
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col>
        <label for="entitySelect1">Stereotype</label>
        <b-form-select
          id="entitySelect1"
          v-model="stereotype_selected"
          :options="stereotypes"
          multiple
          :select-size="5"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <label for="entityInput3">Multiplicity</label>
        <b-form-input
          id="entityInput3"
          v-model="multiplicity"
          size="sm"
        />

        <b-form-checkbox
          v-model="abstract"
          style="margin-right:60px"
          class="mt-2"
        >
          Abstract
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <label for="entitySelect2">Classification</label>
        <b-form-select
          id="entitySelect2"
          v-model="classification"
          :options="securityClassifications"
        />
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <label for="edit_c_os">Operational status</label>
        <b-form-select
          id="edit_c_os"
          v-model="operationalStatus"
          :options="operationalStati"
        />
      </b-col>
      <!--
      <b-col>
        <label for="edit_c_trl">Technology Readiness Level (TRL)</label>
        <b-form-select
          id="edit_c_trl"
          v-model="trl"
          :options="trls"
        />
      </b-col>
      -->
    </b-row>
    <hr>

    <b-row>
      <b-col>
        <b-button
          v-if="selected_entity2.context.labels.includes('Function')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-info"
          class="float-left"
          @click="routeToFunction"
        >
          View/Edit Decomposition & Allocation
        </b-button>
        <b-button
          v-if="selected_entity2.context.labels.includes('System')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-info"
          class="float-left"
          @click="routeToSystem"
        >
          View/Edit System Decomposition
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="float-right"
          :disabled='invalid'
          @click="submitFunction"
        >
          Save Changes
        </b-button>

        <!--<b-button-->
        <!--  v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
        <!--  variant="flat-secondary"-->
        <!--  class="float-right"-->
        <!--&gt;-->
        <!--  Cancel-->
        <!--</b-button>-->
      </b-col>
    </b-row>
    <replace-names-modal :name_swap="name_swap" :new_name="name" @replace="doUpdateExtended" />
    <ComponentImageModal
      :component="id"
    />
  </b-form>
  <div v-else class="text-center mt-4">
    <b-spinner />
    <b-card>
      <span>Loading...</span>
    </b-card>
  </div>
</ValidationObserver>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import coreService from '@/libs/api-services/core-service'
import {
  BButton, BCol, BForm, BFormCheckbox, BFormInput, BFormSelect, BRow,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
import ReplaceNamesModal from '@/components/Domain/Modals/ReplaceNamesModal.vue'
import ComponentImageModal from '@/views/Component/ComponentImageModal.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    TipTapEditor,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BButton,
    ReplaceNamesModal,
    ComponentImageModal,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: '',
      name: '',
      acronym: '',
      abstract: false,
      multiplicity: '',
      stereotype_selected: [],
      classification: '',
      operationalStatus: '',
      // trl: '',
      description: '',
      validity: 'Valid',
      context_data: { attributes: {} },
      name_swap: {},
      showDescriptionEditor: false,
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
    ...mapGetters({
      stereotypes: 'constants/stereotypes',
      securityClassifications: 'constants/securityClassifications',
      operationalStati: 'constants/operationalStatus',
      // trls: 'constants/trls',
    }),
    entityType() {
      return this.selected_entity2.context.labels ? this.selected_entity2.context.labels.filter(e => e !== 'Component') : []
    },
  },
  mounted() {
    this.fillFields(this.selected_entity2)
  },
  methods: {
    validate() {
      if (this.name !== this.selected_entity2.context.details.name) {
        this.$refs.validationObserver
          .validate()
          .then(isValid => {
            this.$http.post('/v1/legacy/domain_model/validate_component_name',
              {
                name: this.name,
                model: this.$store.state.model.id})
              .then(({ data }) => {
                this.$refs.validationObserver.setErrors(data.errors)
              })
              .catch(e => console.error(e))
          })
      }
    },
    editDescription() {
      this.showDescriptionEditor = !this.showDescriptionEditor
    },
    fillFields(n) {
      const con = n.context
      this.name = con.details.name
      this.acronym = con.details.acronym
      this.description = con.details.description
      this.stereotype_selected = con.labels
      this.abstract = con.details.abstract === true
      this.multiplicity = con.details.multiplicity
      this.validity = con.details.validity
      this.id = con.details.id
      this.classification = con.details.classification ?? this.$store.state.model.defaultSecurityClassification
      this.operationalStatus = con.details.operational_status ?? this.$store.state.constants.defaultOperationalStatus.id
      // this.trl = con.details.trl ?? this.$store.state.constants.defaultTrl.id
    },
    async submitFunction(evt) {
      // pre submit user interaction if required
      const oldName = this.selected_entity2.context.details.name
      if (this.selected_entity2.context.details.name !== this.name
        && this.selected_entity2.context.labels
        && !this.selected_entity2.context.labels.includes('Function')) {
        console.log('Get the potentially impacted functions and requirements...')
        const params = {
          model: this.$store.state.model.id,
          new_name: this.name,
        }
        coreService.get(`/v1/legacy/domain_model/get_impacted_fns/${this.selected_entity2.context.details.id}`, { params })
          .then(({ data }) => {
            console.log('Possible Impacts: ', data)
            this.name_swap = data
            if (
              this.name_swap.functions
              && this.name_swap.functions.length === 0
              && this.name_swap.requirements
              && this.name_swap.requirements.length === 0
            ) {
              this.doUpdate()
            } else {
              this.$bvModal.show('replace-names-modal')
            }
          })
      } else {
        await this.doUpdate()
      }
    },
    async doUpdate() {
      const payload = {
        data: {
          name: this.name,
          acronym: this.acronym,
          description: this.description,
          multiplicity: this.multiplicity,
          abstract: Boolean(this.abstract),
          labels: this.stereotype_selected,
          model: this.$store.state.model.id,
          classification: this.classification,
          operational_status: this.operationalStatus,
          // trl: this.trl,
        },
        id: this.id,
      }

      const data = await this.$store.dispatch('domainModel/updateComponent', payload)
      this.$emit('clicked')
      await this.$store.dispatch('domainModel/selectEntity2', data.id)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Updated',
          icon: 'CheckIcon',
          text: `Updated Component ${this.name}`,
          variant: 'success',
        },
      },
      {
        position: 'top-center',
      })
    },
    async doUpdateExtended(replacements) {
      this.$bvModal.hide('replace-names-modal')
      // update the component
      await this.doUpdate()
      // update the extended names
      const payload = {
        model: this.$store.state.model.id,
        replacements,
      }
      coreService.post('/v1/legacy/domain_model/rename_impacted_things', payload)
        .then(({ data }) => {
          this.$store.dispatch('domainModel/selectEntity2', this.selected_entity2.context.details.id)
        })
    },
    routeToFunction(node) {
      this.$router.push(
        {
          name: 'domain_ontology_focus',
          query: { focus: this.id },
        },
      )
    },
    routeToSystem(node) {
      this.$router.push(
        {
          name: 'domain_ontology_focus',
          query: { focus: this.id },
        },
      )
    },
    // Find related components that are children and not the parent
    findChildren() {
      const rels = this.selected_entity2.context.relationships
      const parent = this.selected_entity2.context.parent_rel?.parent

      const output = []

      // Sort through all rels, find related components
      for (let i = 0; i < rels.length; i++) {
        if (rels[i].rel_type === 'TYPE_OF' || rels[i].rel_type === 'PART_OF') {
          if (rels[i].target_props.id !== parent) {
            // Find related comps that aren't parents
            output.push({
              name: rels[i].target_props.qualified_name,
              id: rels[i].target_props.id,
              rel_type: rels[i].rel_type,
            })
          }
        }
      }
      return output
    },
    entityLabel(data) {
      // Depending on the label of the Entity, return a different icon
      switch (data) {
      case 'Resource':
        return 'BookOpenIcon'
      case 'Function':
        return 'ZapIcon'
      case 'Performer':
        return 'UserIcon'
      case 'Standard':
        return 'FileTextIcon'
      case 'Reference':
        return 'EyeIcon'
      case 'Agreement':
        return 'PenToolIcon'
      case 'Capability':
        return 'CheckSquareIcon'
      case 'Activity':
        return 'ActivityIcon'
      case 'Service':
        return 'ToolIcon'
      case 'System':
        return 'ServerIcon'
      case 'Objective':
        return 'TargetIcon'
      default:
        return ''
      }
    },
    openImageModal() {
      this.$bvModal.show('component-image-modal')
    },
    changeRel() {
      const payload = {
        model: this.$store.state.model.id,
        id: this.selected_entity2.context.details.id,
      }
      coreService.post('/v1/legacy/domain_model/change_parent_rel', payload)
        .then(({ data }) => {
          this.$store.dispatch('domainModel/selectEntity2', this.selected_entity2.context.details.id)
          this.$emit('clicked')
        })
    },
    truncateText(text, maxLength) {
      if (!text) return ''
      return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text
    },
  },
}
</script>
