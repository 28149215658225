<template>
  <b-modal
    id="delete-alias-modal"
    title="Delete Alias. Are you sure?"
    size="lg"
    :ok-title="loading ? 'Loading...' : 'Yes, delete alias'"
    :ok-disabled="loading"
    ok-variant="danger"
    cancel-title="Keep"
    cancel-variant="outline-secondary"
    lazy
    no-fade
    @ok.prevent="onSubmit"
  >
    <b-alert
      show
      variant="danger"
    >
      <div class="alert-body d-inline-flex justify-content-between">
        <div class="mr-1">
          <feather-icon
            icon="AlertCircleIcon"
            class="text-white"
            size="30"
          />
        </div>
        <div>
          <p>
            You're about to permanently delete this alias:
            <span class="font-weight-bolder text-primary">{{ data.target_props ? data.target_props.name : 'Empty' }}</span>.
          </p>
          <p>
            To avoid unintended data loss, consider reviewing this action before deleting it.
          </p>
        </div>
      </div>
    </b-alert>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import coreService from '@/libs/api-services/core-service'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
  },
  methods: {
    onSubmit() {
      this.loading = true
      const selectedId = this.selected_entity2.context.details.id
      coreService.delete(`/v1/legacy/domain_model/alias/${this.data.target_props.id}`)
        .then(() => {
          this.$bvModal.hide('delete-alias-modal')
          this.$store.dispatch('domainModel/invalidateCache')
          this.$store.dispatch('domainModel/selectEntity2', selectedId)
          this.$store.dispatch('domainModel/getComponents')
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred when deleting the alias',
              text: `${error}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { this.loading = false })
    },
  },
}
</script>
