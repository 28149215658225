<template>
<ValidationObserver ref="validationObserver" v-slot="{ invalid }">
  <b-modal
    v-if="selected_entity && selected_entity.context"
    id="add-entity-modal"
    title="Create Entity"
    size="lg"
    ok-title="Create Entity"
    ok-variant="success"
    :ok-disabled="invalid"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    @shown="setData"
    @ok="onSubmit"
  >
    <b-form>
      <div class="d-inline-flex justify-content-between w-100 mb-1">
        <div style="width: 160% !important;">
          <label for="add_entity_input_1">Entity name</label>
            <validation-provider
              v-slot="{ errors }"
              vid="entity_name"
              name="Entity name"
              rules="required"
            >

            <b-form-input
              id="add_entity_input_1"
              v-model="retainedFormData.name"
              placeholder="Entity name..."
              :state="errors.length > 0 ? false:null"
              @update="validate"
              @focusout="validate"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>

        <div class="w-100 ml-2">
          <label for="add_entity_input_2">Acronym</label>
          <b-form-input id="add_entity_input_2" v-model="formData.acronym" placeholder="Acronym..." />
        </div>
      </div>

      <div>
        <label for="add_entity_rich_1">Description</label>
        <tip-tap-editor
          id="add_entity_rich_1"
          v-model="formData.description"
          placeholder="Describe this entity..."
          min-height="6"
        />
      </div>

      <div class="d-inline-flex justify-content-between w-100 mt-1">
        <div class="w-100">
          <b-form-group>
            <label for="entity_type">Relationship type</label>
            <b-form-radio-group
              id="entity_type"
              v-model="formData.type"
              :options="[
                {text: 'Aggregation', value: 'aggregation'},
                {text: 'Inheritance', value: 'inheritance'},
              ]"
              class="mt-50 ml-75"
            />
          </b-form-group>
        </div>

        <div class="w-100">
          <label for="add_entity_input_3">Multiplicity</label>
          <b-form-input id="add_entity_input_3" v-model="retainedFormData.multiplicity" />
        </div>
      </div>

      <hr class="my-1">

      <div>
        <b-form-group label="Stereotype(s)" label-for="add_entity_select_1">
          <v-select
            id="add_entity_select_1"
            v-model="formData.labels"
            placeholder="-- Select Stereotype(s) --"
            label="name"
            :options="stereotypes"
            :reduce="obj => obj.id"
            multiple
          >
            <template #option="{ name, description }">
              <div class="d-flex flex-column" :title="description">
                <p class="m-0">{{ name }}</p>
                <p class="m-0 font-small-3 text-muted">{{ description }}</p>
              </div>
            </template>
          </v-select>
        </b-form-group>
      </div>

      <div class="mt-1">
        <b-form-group label="Security Classification" label-for="add_entity_select_2">
          <b-form-select
            id="add_entity_select_2"
            v-model="formData.classification"
            :options="securityClassifications"
          />
        </b-form-group>
      </div>
    </b-form>
  </b-modal>
</ValidationObserver>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import coreService from '@/libs/api-services/core-service'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    TipTapEditor,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    formData: {
      acronym: '',
      description: '',
      type: 'aggregation',
      labels: [],
      classification: '',
    },
    retainedFormData: {
      name: '',
      multiplicity: '0..*',
    },
    shown: false,
  }),
  computed: {
    ...mapState({
      selected_entity: state => state.domainModel.selected_entity2,
    }),
    ...mapGetters({
      stereotypes: 'constants/stereotypes',
      securityClassifications: 'constants/securityClassifications',
    }),
  },
  mounted() {
    this.formData.labels = this.selected_entity?.context.labels
  },
  updated() {
    if (!this.shown) return
    const headerEle = document.querySelector('.modal-header')
    const modalEle = document.querySelector('.modal-dialog')
    let dragging = false
    const dragOffset = { left: 0, top: 0 }
    function mouseDownListener(ev) {
      dragging = true
      const rect = modalEle.getBoundingClientRect()
      dragOffset.left = ev.clientX - rect.left
      dragOffset.top = ev.clientY - rect.top
    }
    function mouseUpListener(ev) {
      dragging = false
    }
    function mouseMoveListener(ev) {
      if (dragging) {
        modalEle.style.position = 'fixed'
        modalEle.style.left = `${ev.clientX - dragOffset.left}px`
        modalEle.style.top = `${ev.clientY - dragOffset.top}px`
      }
    }
    headerEle.addEventListener('mousedown', mouseDownListener)
    document.addEventListener('mousemove', mouseMoveListener)
    document.addEventListener('mouseup', mouseUpListener)
  },
  created() {
    // Check if there's previous modal data in localStorage
    const previousData = JSON.parse(localStorage.getItem('previousModalData'))
    if (previousData) {
      // Use previous modal data if it exists
      this.retainedFormData = previousData
    }
  },
  methods: {
    validate() {
      this.$refs.validationObserver
        .validate()
        .then(isValid => {
          this.$http.post('/v1/legacy/domain_model/validate_component_name',
            {
              name: this.retainedFormData.name,
              model: this.$store.state.model.id})
            .then(({ data }) => {
              this.$refs.validationObserver.setErrors(data.errors)
            })
            .catch(e => console.error(e))
        })
    },
    setData() {
      if (this.selected_entity?.context) {
        const lbls = this.selected_entity?.context.labels
        this.formData.labels = lbls
        if (typeof lbls === 'string') {
          this.formData.labels = lbls.replace(/'/g, '"')
          this.formData.labels = JSON.parse(lbls)
        }
        if (lbls
          && (
            lbls.includes('Function')
            || lbls.includes('Capability')
            || lbls.includes('Activity')
          )
        ) {
          this.retainedFormData.multiplicity = 1
        }
        this.shown = true
      }
      this.formData.classification = this.$store.state.model.defaultSecurityClassification
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.shown = false
      if (this.formData.labels && this.formData.labels.indexOf('Component') === -1) {
        this.formData.labels.unshift('Component')
      }
      const params = {
        model: this.$store.state.model.id,
        parent_rel: this.formData.type,
        parent: this.selected_entity.context.details.id,
        ...this.formData,
        ...this.retainedFormData,
      }
      coreService.post('/v1/legacy/domain_model/composition', params)
        .then(({ data }) => {
          this.$emit('added', data.id)
          localStorage.setItem('previousModalData', JSON.stringify(this.retainedFormData))
          this.$bvModal.hide('add-entity-modal')
          // this.clearForm()
          // DO NOT Clear the form for adding components -
          // it is best to leave as usually add many of the same stereotype at once (e.g. Functional/System Decomp)
        }).catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update Entity',
              text: `An error occurred when attempting to update Entity.
              Server returned Status ${error.response.data}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
#add-entity-modal .modal-dialog {
  max-width: 950px !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
