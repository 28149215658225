<template>
  <div v-if="selected_entity2" class="px-50">
    <div class="mt-1 d-inline-flex w-100 justify-content-between">
      <h4>
        Attributes
      </h4>
      <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      size="sm"
      variant="outline-success"
      @click="$bvModal.show('add-attribute-modal')"
    >
      <feather-icon icon="PlusIcon" class="text-success" />
      Add Attribute
    </b-button>
    </div>
    <div v-if="selected_entity2.attributes.length === 0">
      <p class="text-muted">
        There are no attributes for this element.
      </p>
    </div>
    <div v-else>
      <b-table id="prop_table" class="mt-50" striped hover small :items="selected_entity2.attributes" :fields="fields">
        <template v-slot:cell(name)="data">
          <span class="text-primary"><b>{{ data.item.name }}</b></span>: {{ data.item.type }}
          <span v-if="data.item.unit && data.item.unit !== ''"> ({{ data.item.unit }})</span>
          <span v-if="data.item.value && data.item.value !== ''">
              = {{ data.item.value }}
          </span><br>
        </template>
        <template v-slot:cell(details)="data">
          <div v-if="(data.item.min && data.item.min !== '') || (data.item.max && data.item.max !== '') || (data.item.required && data.item.required !== 'false') || (data.item.unique && data.item.unique !== 'false')">
            <div v-if="(data.item.required && data.item.required !== 'false') || (data.item.unique && data.item.unique !== 'false')">
              <span v-if="data.item.required && data.item.required !== 'false'">Required</span> <span v-if="data.item.unique && data.item.unique !== 'false'">Unique</span><br>
            </div>
            <div v-if="(data.item.min && data.item.min !== '') || (data.item.max && data.item.max !== '')">
              <span v-if="data.item.min && data.item.min !== ''">Min: {{ data.item.min }}</span> <span v-if="data.item.max && data.item.max !== ''">Max: {{ data.item.max }}</span><br>
            </div>
          </div>
          <div v-else>
            No Constraints
          </div>
          <div v-if="data.item.default && data.item.default !== ''">
            Value (Default): {{ data.item.default }}
          </div>
          <div v-if="data.item.value && data.item.value !== ''">
              <b>Value (Actual):</b> {{ data.item.value }}
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button-group>
            <b-button title="Edit Attribute" variant="flat-success" size="sm" class="p-50">
              <feather-icon icon="EditIcon" @click="editAttribute(data.item)" />
            </b-button>
            <b-button title="Delete Attribute" variant="flat-danger" size="sm" class="p-50">
              <feather-icon icon="TrashIcon" @click="deleteAttribute(data.item)" />
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </div>

    <hr class="mt-3">

    <!-- PROPERTIES -->
    <div class="d-inline-flex w-100 justify-content-between">
      <h4>
        Properties
      </h4>
        <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="outline-success"
        @click="$bvModal.show('add-property-modal')"
      >
        <feather-icon icon="PlusIcon" class="text-success" />
        Add Property
      </b-button>
    </div>
    <div v-if="selected_entity2.properties.length === 0">
      <p class="text-muted">
        There are no properties for this element.
      </p>
    </div>
    <div v-else>
      <b-table id="prop_table" class="mt-50" striped hover small :items="selected_entity2.properties" :fields="p_fields">
        <template v-slot:cell(name)="data">
          <span class="text-primary"><b>{{ data.item.name }}</b></span>: {{ data.item.type }}
          <span v-if="data.item.unit && data.item.unit !== ''"> ({{ data.item.unit }})</span>
          <span v-if="data.item.value && data.item.value !== ''">
              = {{ data.item.value }}
          </span><br>
        </template>
        <template v-slot:cell(details)="data">
          <div v-if="(data.item.min && data.item.min !== '') || (data.item.max && data.item.max !== '')">
            <span v-if="data.item.min && data.item.min !== ''">Min: {{ data.item.min }}</span> <span v-if="data.item.max && data.item.max !== ''">Max: {{ data.item.max }}</span>
          </div>
          <div v-else>
            No constraints
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button-group>
            <b-button title="Edit Property" variant="flat-success" size="sm" class="p-50">
              <feather-icon icon="EditIcon" @click="editProperty(data.item)" />
            </b-button>
            <b-button title="Create Quality Attribute Requirement for Property" variant="flat-success" size="sm" class="p-50">
              <feather-icon icon="TargetIcon" @click="createQARFromProperty(data.item)" />
            </b-button>
            <b-button title="Delete Property" variant="flat-danger" size="sm" class="p-50">
              <feather-icon icon="TrashIcon" @click="deleteProperty(data.item)" />
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </div>

    <hr class="mt-3">

    <!-- TRL Assessment -->
    <div>
      <div class="mt-1 mb-1 d-inline-flex w-100 justify-content-between">
        <h4 title="Technology Readiness Level (TRL) Assessments">
          TRL Assessments
        </h4>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="outline-success"
          @click="addTRL()"
        >
          <feather-icon icon="PlusIcon" class="text-success" />
          Add TRL Assessment
        </b-button>
      </div>
      <b-list-group
        v-if="selected_entity2.trls.length > 0"
      >
        <b-list-group-item
          v-for="(trl, index) in selected_entity2.trls"
          :key="index"
          :active="false"
        >
          <b-row>
            <b-col cols="10">
              <div v-if="trl.release">
                At the end of <span class="text-primary">{{ trl.release.name }}</span> (
                <span v-if="trl.release.end_date && trl.release.end_date !== ''">{{ trl.release.end_date | moment }}</span>
                <span v-else>end date unknown</span>
                ) the entity <span class="text-primary">{{ selected_entity2.context.details.qualified_name }}</span> was assessed as having a TRL of
                <TechReadinessLevel :trl="trl.trl" :no-label="true"/>
              </div>
              <div v-else-if="trl.trl === 0">
                The TRL for the entity <span class="text-primary">{{ selected_entity2.context.details.qualified_name }}</span>
                <span class="text-danger"> has not been defined</span>
              </div>
              <div v-else>
                The entity <span class="text-primary">{{ selected_entity2.context.details.qualified_name }}</span> was assessed as having a TRL of
                <TechReadinessLevel :trl="trl.trl" :no-label="true"/>
                at an unknown point in time.
              </div>
              <hr class="mt-1">
              <div v-if="trl.justification && trl.justification !== ''">
                This is based on the following justification:
                <div class="scroll-400 pl-50" v-sanitized-html="trl.justification" />
              </div>
              <div v-else>
                <span class="font-small-3 text-muted">No justification has been provided.</span>
              </div>
              <div v-if="trl.evidence.length > 0 && trl.accepted">
                The following is the accepted evidence:
              </div>
              <div v-else-if="trl.evidence.length > 0 && !trl.accepted">
                The following is cited as evidence:
              </div>
              <div v-else>
                <span class="font-small-3 text-muted">No evidence has been provided.</span>
              </div>
              <div v-if="trl.evidence.length > 0" class="scroll-400">
                <ul>
                  <template v-for="(item, index) in trl.evidence">
                    <li :key="index" class="pl-50" v-sanitized-html="evidenceToStr(item)" />
                  </template>
                </ul>
              </div>
            </b-col>
            <b-col cols="2" class="p-0">
              <b-button-toolbar>
                <b-button title="Edit TRL Assessment" variant="flat-success" size="sm" class="p-50">
                  <feather-icon icon="EditIcon" @click="editTRL(trl)" />
                </b-button>
                <b-button title="Delete TRL Assessment" variant="flat-danger" size="sm" class="p-50">
                  <feather-icon icon="TrashIcon" @click="deleteTRL(trl)" />
                </b-button>
              </b-button-toolbar>
            </b-col>
          </b-row>

        </b-list-group-item>
      </b-list-group>

      <div v-else>
        <span class="text-muted">No linked TRL Assessments</span>
      </div>
    </div>

    <AddAttribute />
    <EditAttribute />
    <DeleteAttribute />
    <AddProperty />
    <EditProperty />
    <DeleteProperty />
    <AddTRL @trl="postTRL"/>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex'
import coreService from '@/libs/api-services/core-service'
import AddAttribute from '@/components/Domain/Modals/Properties/AttributeAdd.vue'
import EditAttribute from '@/components/Domain/Modals/Properties/AttributeEdit.vue'
import DeleteAttribute from '@/components/Domain/Modals/Properties/AttributeDelete.vue'
import AddProperty from '@/components/Domain/Modals/Properties/PropertyAdd.vue'
import EditProperty from '@/components/Domain/Modals/Properties/PropertyEdit.vue'
import DeleteProperty from '@/components/Domain/Modals/Properties/PropertyDelete.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddTRL from '@/components/Domain/Modals/Context/TRLAdd.vue'
import Ripple from 'vue-ripple-directive'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import Vue from 'vue'
import TechReadinessLevel from '@/components/Components/TechReadinessLevel.vue'
import { filterTags } from '@core/utils/filter'

export default {
  components: {
    TechReadinessLevel,
    AddAttribute,
    EditAttribute,
    DeleteAttribute,
    AddProperty,
    EditProperty,
    DeleteProperty,
    AddTRL,
  },
  directives: {
    Ripple,
  },
  filters: {
    moment(date) {
      return moment(date).format('LL')
    },
  },
  data() {
    return {
      name: '',
      context: { attributes: [] },
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'details',
          label: 'Constraints/Details',
        },
        {
          key: 'actions',
          label: 'Actions',
          thClass: 'text-center',
          thStyle: 'width: 2rem',
        },
      ],
      p_fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'details',
          label: 'Constraints',
        },
        {
          key: 'actions',
          label: 'Actions',
          thClass: 'text-center',
          thStyle: 'width: 2rem',
        },
      ],
      c_fields: [
        {
          key: 'name',
          label: 'Name / Tag',
          sortable: true,
        },
        {
          key: 'gist',
          label: 'Desc.',
        },
        {
          key: 'requirement',
          label: 'QA Requirement',
          thClass: 'text-center',
        },
        {
          key: 'actions',
          label: 'Actions',
          thClass: 'text-center',
          thStyle: 'width: 2rem',
        },
      ],
      attributes: [],
      properties: [],
      trls: [],
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2', 'selected_trl']),
    ...mapGetters({
      allTRLs: 'constants/trls',
    }),
    default_class() {
      return this.$store.state.model.defaultSecurityClassification
    },
  },
  watch: {
    selected_entity2(newVal) {
      this.name = newVal.name
    },
    component_context(newVal) {
      newVal.attributes.forEach(element => {
        element.constraints = (() => (`${element.required ? 'Required' : ''}  ${element.unique ? 'Unique' : ''}`))()
        element.actions = element.id
        this.attributes.push(element)

        // eslint-disable-next-line no-nested-ternary
        console.debug(element.required ? 'required' : `${element.unique}` ? 'unique' : '')
      })
      newVal.properties.forEach(element => {
        element.actions = element.id
        this.properties.push(element)
      })
      newVal.trls.forEach(element => {
        element.actions = element.id
        this.trls.push(element)
      })
      this.context = newVal
      console.debug(newVal)
    },
  },
  methods: {
    trlToStr(trl) {
      const result = this.allTRLs.find(element => element.id === trl)
      return result.name ? result.name : '0 - Undefined'
    },
    evidenceToStr(oqe) {
      let result = `<span class="text-primary">${oqe.title}</span>`
      result += '<small>'
      result += oqe.document_link ? ` [<a href="${oqe.document_link}" target="_blank">${oqe.document_link}</a>]` : ''
      result += oqe.document_id ? ` (${oqe.document_id})` : ''
      result += oqe.version ? ` version ${oqe.version}` : ''
      result += oqe.document_date ? ` - ${moment(oqe.document_date, 'LL')}` : ''
      result += '</small>'
      return result
    },
    editAttribute(val) {
      this.$store.dispatch('domainModel/selectAttribute', val)
        .then(() => {
          this.$bvModal.show('edit-attribute-modal')
        })
    },
    deleteAttribute(val) {
      this.$store.dispatch('domainModel/selectAttribute', val)
        .then(() => {
          this.$bvModal.show('delete-attribute-modal')
        })
    },
    editProperty(val) {
      this.$store.dispatch('domainModel/selectProperty', val)
        .then(() => {
          this.$bvModal.show('edit-property-modal')
        })
    },
    deleteProperty(val) {
      this.$store.dispatch('domainModel/selectProperty', val)
        .then(() => {
          this.$bvModal.show('delete-property-modal')
        })
    },
    addTRL() {
      this.$store.dispatch('domainModel/selectTRL', null)
        .then(() => {
          this.$bvModal.show('edit-trl-modal')
        })
    },
    editTRL(val) {
      this.$store.dispatch('domainModel/selectTRL', val)
        .then(() => {
          this.$bvModal.show('edit-trl-modal')
        })
    },
    async deleteTRL(val) {
      const opts = {
        centered: true,
        title: 'Confirm Delete TRL Assessment',
        okVariant: 'danger',
        okTitle: 'Delete TRL Assessment',
      }
      const userConfirmation = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete '${filterTags(val.justification)}'?`, opts,
      )
      if (!userConfirmation) return
      await coreService.componentApi.deleteComponentTrlAssessment(this.selected_entity2.context.details.id, val.id)
      await this.$store.dispatch('domainModel/selectEntity2', this.selected_entity2.context.details.id)
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: 'Deleted TRL Assessment',
          icon: 'CheckIcon',
          text: `TRL Assessment Deleted from: ${this.selected_entity2.context.details.name}`,
          variant: 'success',
        },
      })
    },
    postTRL() {
      this.$store.dispatch('domainModel/selectEntity2', this.selected_entity2.context.details.id)
    },
    addConstraint() {
      this.$store.dispatch('domainModel/selectConstraint', null)
        .then(() => {
          console.log('Properties::addConstraint: ')
          const selectedId = this.selected_entity2.context.details.id
          const payload = {
            model: this.$store.state.model.id,
            name: 'New Constraint',
            id: uuidv4(),
            requirements: [],
          }
          coreService.post(`/v1/legacy/domain_model/add_constraint/${selectedId}`, payload).then(async result => {
            this.loading_status = false
            this.$bvModal.hide('add-constraint-modal')
            const pl = { ...result.data, ...payload, ...{ name: '' } }
            await this.$store.dispatch('domainModel/selectConstraint', pl)
            this.$bvModal.show('edit-constraint-modal')
          })
        })
    },
    editConstraint(val) {
      this.$store.dispatch('domainModel/selectConstraint', val)
        .then(() => {
          console.log('Properties::editConstraint: ', val)
          this.$bvModal.show('edit-constraint-modal')
        })
    },
    deleteConstraint(val) {
      this.$store.dispatch('domainModel/selectConstraint', val)
        .then(() => {
          this.$bvModal.show('delete-constraint-modal')
        })
    },
    genConstraintReqs(val) {
      console.debug('Generate requirements from constraint: ', val)
      this.$store.dispatch('domainModel/selectConstraint', val)
        .then(() => {
          this.$bvModal.show('generate-constraint-requirements-modal')
        })
    },
    createQARFromProperty(val) {
      this.$store.dispatch('domainModel/selectProperty', val)
        .then(() => {
          const payload = {
            property_id: val.id,
            model: this.$store.state.model.id,
          }
          this.$http.post(`/v1/legacy/domain_model/create_constraint_from_property/${this.selected_entity2.context.details.id}`, payload).then(async result => {
            this.loading_status = false
            const pl = { ...result.data }
            console.log('result: ', pl)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Created Quality Attribute Requirement',
                text: 'from property',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            // await this.$store.dispatch('domainModel/selectConstraint', pl)
            // this.$bvModal.show('edit-constraint-modal')
          })
        })
    },
  },
}
</script>

<style scoped>
  #prop_table {
    font-size: 0.8rem;
  }
  #constraint_table {
    font-size: 0.8rem;
  }
  .constraint-table-scroll {
    width: 100%;
    max-height: 90vh;
    overflow-x: auto;
    overflow-y: auto;
  }
  .scroll-400 {
    overflow-y: auto;
    max-height: 400px;
  }
</style>
