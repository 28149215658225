var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.selected_entity && _vm.selected_entity.context)?_c('b-modal',{attrs:{"id":"add-entity-modal","title":"Create Entity","size":"lg","ok-title":"Create Entity","ok-variant":"success","ok-disabled":invalid,"cancel-title":"Discard","cancel-variant":"outline-secondary"},on:{"shown":_vm.setData,"ok":_vm.onSubmit}},[_c('b-form',[_c('div',{staticClass:"d-inline-flex justify-content-between w-100 mb-1"},[_c('div',{staticStyle:{"width":"160% !important"}},[_c('label',{attrs:{"for":"add_entity_input_1"}},[_vm._v("Entity name")]),_c('validation-provider',{attrs:{"vid":"entity_name","name":"Entity name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"add_entity_input_1","placeholder":"Entity name...","state":errors.length > 0 ? false:null},on:{"update":_vm.validate,"focusout":_vm.validate},model:{value:(_vm.retainedFormData.name),callback:function ($$v) {_vm.$set(_vm.retainedFormData, "name", $$v)},expression:"retainedFormData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-100 ml-2"},[_c('label',{attrs:{"for":"add_entity_input_2"}},[_vm._v("Acronym")]),_c('b-form-input',{attrs:{"id":"add_entity_input_2","placeholder":"Acronym..."},model:{value:(_vm.formData.acronym),callback:function ($$v) {_vm.$set(_vm.formData, "acronym", $$v)},expression:"formData.acronym"}})],1)]),_c('div',[_c('label',{attrs:{"for":"add_entity_rich_1"}},[_vm._v("Description")]),_c('tip-tap-editor',{attrs:{"id":"add_entity_rich_1","placeholder":"Describe this entity...","min-height":"6"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1),_c('div',{staticClass:"d-inline-flex justify-content-between w-100 mt-1"},[_c('div',{staticClass:"w-100"},[_c('b-form-group',[_c('label',{attrs:{"for":"entity_type"}},[_vm._v("Relationship type")]),_c('b-form-radio-group',{staticClass:"mt-50 ml-75",attrs:{"id":"entity_type","options":[
                {text: 'Aggregation', value: 'aggregation'},
                {text: 'Inheritance', value: 'inheritance'} ]},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}})],1)],1),_c('div',{staticClass:"w-100"},[_c('label',{attrs:{"for":"add_entity_input_3"}},[_vm._v("Multiplicity")]),_c('b-form-input',{attrs:{"id":"add_entity_input_3"},model:{value:(_vm.retainedFormData.multiplicity),callback:function ($$v) {_vm.$set(_vm.retainedFormData, "multiplicity", $$v)},expression:"retainedFormData.multiplicity"}})],1)]),_c('hr',{staticClass:"my-1"}),_c('div',[_c('b-form-group',{attrs:{"label":"Stereotype(s)","label-for":"add_entity_select_1"}},[_c('v-select',{attrs:{"id":"add_entity_select_1","placeholder":"-- Select Stereotype(s) --","label":"name","options":_vm.stereotypes,"reduce":function (obj) { return obj.id; },"multiple":""},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var name = ref.name;
              var description = ref.description;
return [_c('div',{staticClass:"d-flex flex-column",attrs:{"title":description}},[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(name))]),_c('p',{staticClass:"m-0 font-small-3 text-muted"},[_vm._v(_vm._s(description))])])]}}],null,true),model:{value:(_vm.formData.labels),callback:function ($$v) {_vm.$set(_vm.formData, "labels", $$v)},expression:"formData.labels"}})],1)],1),_c('div',{staticClass:"mt-1"},[_c('b-form-group',{attrs:{"label":"Security Classification","label-for":"add_entity_select_2"}},[_c('b-form-select',{attrs:{"id":"add_entity_select_2","options":_vm.securityClassifications},model:{value:(_vm.formData.classification),callback:function ($$v) {_vm.$set(_vm.formData, "classification", $$v)},expression:"formData.classification"}})],1)],1)])],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }