<template>
  <b-modal
    id="edit-trl-modal"
    :title="addOrEditText + ' Technology Readiness Level (TRL) Assessment'"
    size="lg"
    body-class="scroll-80vh"
    @ok="submitFunction"
    @show="getData"
    @hide="onHide"
  >
    <div>
      <b-form-group for="trl_trl" label="Technology Readiness Level (TRL)">
        <b-form-select
          id="trl_trl"
          v-model="trl"
          :options="trls"
        />
      </b-form-group>
      <b-form-group label-for="trl_just" class="label-control">
        <label for="trl_just">Justification</label>
        <tip-tap-editor
          id="trl_just"
          v-model="justification"
          placeholder="Justify the assessment of the TRL..."
          :allow-image-upload="false"
          min-height="6"
          max-height="8"
        />
      </b-form-group>
      <b-row>
        <b-col cols="12">
          <b-form-group for="trl_ev" title="Cite Supporting Evidence. Hold Shift to select multiple. Hold Ctrl to deselect a single item." label="Cite Supporting Evidence">
            <b-form-select
              id="trl_ev"
              v-model="evidence"
              :options="allEvidence"
              :select-size="5"
              multiple
            >
              <template #first>
                <b-form-select-option value="" disabled>-- Please select an option --</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <label for="trl_rel">Effective at end of Release/Epoch</label>
          <b-form-select
            id="trl_rel"
            v-model="release"
            :options="allReleases"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="trl_clas">Classification</label>
          <b-form-select
            id="trl_clas"
            v-model="classification"
            :options="securityClassifications"
          />
        </b-col>
      </b-row>
    </div>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="outline-secondary" @click="cancel()">
        Discard
      </b-button>

      <b-button variant="success" :disabled="loading_status" @click="ok()">
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Saving changes...
        </span>
        <span v-else-if="selected_trl">
          Edit TRL Assessment
        </span>
        <span v-else>
          Create TRL Assessment
        </span>
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import {
  BButton,
  BFormSelect,
  BModal,
  BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import moment from 'moment'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
import coreService from '@/libs/api-services/core-service'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormSelect,
    BSpinner,
    BModal,
    BButton,
    TipTapEditor,
  },
  data() {
    return {
      filteredOptions: [],
      // DOM and Payload
      model: this.$store.state.model.id,
      trl: 0,
      justification: '',
      release: '',
      evidence: [],
      classification: this.$store.state.model.defaultSecurityClassification,
      loading_status: false,
      selectedTRL: null,
      allReleases: [],
      allEvidence: [],
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2', 'selected_trl']),
    ...mapGetters({
      trls: 'constants/trls',
      securityClassifications: 'constants/securityClassifications',
    }),
    ...mapState({
      releases: state => state.releases.releases,
    }),
    addOrEditText() {
      return this.selected_trl ? 'Edit' : 'Add'
    },
  },
  watch: {
    selected_trl(newVal) {
      this.fillFields(newVal)
    },
  },
  mounted() {
    this.$store.dispatch('releases/getReleases')
    if (this.selected_trl) {
      this.fillFields(this.selected_trl)
    } else {
      this.resetFields()
    }
  },
  methods: {
    onHide() {
      this.resetFields()
    },
    getData() {
      const modelId = this.$store.state.model.id
      this.$http.get('/v1/legacy/releases', { params: { model: modelId } })
        .then(({ data }) => {
          this.allReleases = data.map(x => ({
            value: x.id,
            html: `${x.name}${x.end_date && x.end_date !== '' ? ` ending ${moment(x.end_date).format('DD-MM-YYYY')}` : ''}`,
          }))
          store.dispatch('qualificationEvidence/fetchEvidenceList')
            .then(data => {
              this.allEvidence = data.map(x => ({
                value: x.id,
                html: x.title,
              }))
              if (this.selected_trl) {
                this.fillFields(this.selected_trl)
              } else {
                this.resetFields()
              }
              this.loading_status = false
            })
        })
    },
    resetFields() {
      this.model = this.$store.state.model.id
      this.trl = 0
      this.justification = ''
      this.release = ''
      this.evidence = []
      this.classification = this.$store.state.model.defaultSecurityClassification
      this.loading_status = false
    },
    fillFields(val) {
      if (val === null) return
      this.trl = val.trl
      this.justification = val.justification
      this.release = val.release ? val.release.id : ''
      this.evidence = val.evidence.map(e => e.id)
      this.classification = val.classification || this.$store.state.model.defaultSecurityClassification
    },
    async submitFunction(e) {
      e.preventDefault()
      const payload = {
        trl: this.trl,
        justification: this.justification,
        release: this.release,
        evidence: this.evidence,
        classification: this.classification,
      }
      if (!this.selected_trl) {
        await coreService.componentApi.createComponentTrlAssessment(this.selected_entity2.context.details.id, payload)
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Added TRL Assessment',
            icon: 'CheckIcon',
            text: `TRL Assessment Added for: ${this.selected_entity2.context.details.name}`,
            variant: 'success',
          },
        })
        this.$bvModal.hide('edit-trl-modal')
        this.$emit('trl')
      } else {
        await coreService.componentApi.updateTrlAssessment(this.selected_trl.id, payload)
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Edited TRL Assessment',
            icon: 'CheckIcon',
            text: `TRL Assessment Updated for: ${this.selected_entity2.context.details.name}`,
            variant: 'success',
          },
        })
        this.$bvModal.hide('edit-trl-modal')
        this.$emit('trl')
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/plugins/extensions/ext-component-context-menu.scss';
@import '~@core/scss/vue/libs/vue-autosuggest.scss';
  .scroll-400 {
    overflow-y: auto;
    max-height: 400px;
  }
  .scroll-80vh {
    overflow-y: auto;
    max-height: 80vh;
  }
</style>
