<template>
  <div>
    <h4>{{ name ? 'Compliance for '+name : 'Compliance for Entity' }}  <b-badge class="float-right" variant="primary" /> </h4>
    <h6 class="mt-2 text-primary">
      Select Specification to record compliance against
    </h6>
    <specification-select v-model="specification" />
    <b-table
      responsive
      striped
      hover
      show-empty
      small
      class="position-relative"
      :fields="table_fields"
      :items="requirements"
    >
      <template v-slot:cell(requirement)="data">
        <small>
          <b>{{ data.item.display_id }}</b><br>
          <p>{{ data.item.display_text }}</p>
          [{{ data.item.priority }}]
        </small>
      </template>
      <template v-slot:cell(compliance)="data">
        <div v-if="data.item.priority !== 'Heading' && data.item.priority !== 'Advice'">
          <b-form-select :id="data.item.id" :options="complianceOptions" :v-model="data.item.compliance" :value="data.item.compliance" size="sm" @change="doChangeCompliance($event, data.item)" />
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import coreService from '@/libs/api-services/core-service'
import Ripple from 'vue-ripple-directive'
import SpecificationSelect from '@/components/Specifications/Forms/Select.vue'

export default {
  components: {
    SpecificationSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      componentId: null,
      name: '',
      specification: null,
      requirements: [],
      complianceOptions: ['Not Evaluated', 'Compliant', 'Non-Compliant', 'Partially Compliant'],
      loading: false,
      table_fields: [
        {
          key: 'section',
          label: 'Section',
        },
        {
          key: 'requirement',
          label: 'Requirement',
        },
        {
          key: 'compliance',
          label: 'Compliance',
        },
      ],
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
  },
  watch: {
    specification(specId) {
      this.loading = true
      const params = {
        model: this.$store.state.model.id,
      }
      coreService.get(`/v1/legacy/domain_model/compliance/${this.componentId}/${specId}`, { params })
        .then(({ data }) => {
          this.requirements = data
          this.loading = false
        })
    },
  },
  mounted() {
    this.fillFields(this.selected_entity2)
  },
  methods: {
    fillFields(n) {
      this.loading = true
      const con = n.context
      this.name = con.details.name
      this.componentId = con.details.id
      const params = {
        model: this.$store.state.model.id,
      }
      coreService.get(`/v1/legacy/domain_model/compliance/${this.componentId}/${this.specification}`, { params })
        .then(({ data }) => {
          this.requirements = data
          this.loading = false
        })
    },
    doChangeCompliance(event, data) {
      console.log('Change: ', event, data)
      const fields = {
        model: this.$store.state.model.id,
        compliance: event,
        component: this.componentId,
        requirement: data.id,
      }
      coreService.post('/v1/legacy/domain_model/record_compliance_for_req', fields)
        .then(({ data }) => {
          console.log('Compliance Record Created: ', data)
        })
    },
  },
}
</script>
