<template>
  <b-modal
    id="update-instance-modal"
    title="Update Instance"
    size="lg"
    @show="onShow"
  >
    <div class="d-inline-flex w-100 justify-content-between">
      <div class="w-75 mr-50">
        <label for="update-instance-modal-name">Instance name</label>
        <b-form-input
          id="update-instance-modal-name"
          v-model="instanceName"
          :disabled="loading"
          placeholder="Instance name"
        />
      </div>
      <div>
        <label for="update-instance-modal-version">Version</label>
        <b-form-input
          id="update-instance-modal-version"
          v-model="instanceVersion"
          :disabled="loading"
          placeholder="Instance version"
        />
      </div>
    </div>

    <b-form-group
      label="Description"
      label-for="update-instance-modal-description"
      class="mt-1"
    >
      <tip-tap-editor
        id="update-instance-modal-description"
        v-model="instanceDescription"
        :disabled="loading"
        :allow-image-upload="false"
        min-height="8"
        max-height="8"
        placeholder="Describe this instance..."
      />
    </b-form-group>

    <template #modal-footer="{ cancel }">
      <b-button variant="outline-secondary" @click="cancel">
        Cancel
      </b-button>
      <b-button variant="success" :disabled="loading || !isInstanceNameValid" @click="onSubmit">
        {{ loading ? 'Updating Instance...' : 'Update Instance' }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
import coreService from '@/libs/api-services/core-service'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'InstanceUpdateModal',
  components: {
    TipTapEditor,
  },
  props: {
    instance: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const loading = ref(false)
    const instanceName = ref('')
    const instanceVersion = ref('')
    const instanceDescription = ref('')
    const isInstanceNameValid = computed(() => instanceName?.value?.trim().length > 0 || false)

    const onShow = () => {
      loading.value = false
      instanceName.value = props.instance.name
      instanceVersion.value = props.instance.version
      instanceDescription.value = props.instance.description
    }

    const onSubmit = () => {
      loading.value = true

      const { modelId } = router.currentRoute.params
      const params = {
        model: modelId,
        name: instanceName.value.trim(),
        version: instanceVersion.value.trim(),
        description: instanceDescription.value,
      }

      coreService.put(`/v1/legacy/domain_model/instance/${props.instance.id}`, params)
        .then(({ data }) => {
          context.emit('updated', data)
          context.root.$bvModal.hide('update-instance-modal')
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred when updating the instance',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { loading.value = false })
    }

    return {
      loading,
      instanceName,
      isInstanceNameValid,
      instanceVersion,
      instanceDescription,

      onSubmit,
      onShow,
    }
  },
}
</script>
