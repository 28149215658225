<template>
  <b-modal
    id="delete-property-modal"
    title="Delete Property"
    size="lg"
    centered
    @ok="deleteSelectedProperty"
  >
    <p>
      Are you sure you want to delete the property:
      <span class="text-primary font-weight-bolder">{{ selected_property.name }}</span>
      ?
    </p>

    <p class="text-danger font-weight-bolder">
      This action cannot be undone.
    </p>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="outline-secondary" @click="cancel()">
        No, keep it
      </b-button>
      <b-button variant="danger" @click="ok()">
        Yes, delete it
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import coreService from '@/libs/api-services/core-service'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('domainModel', ['selected_entity2', 'selected_property']),
  },
  methods: {
    deleteSelectedProperty(evt) {
      evt.preventDefault()
      const payload = {
        id: this.selected_property.id,
        model: this.$store.state.model.id,
      }
      coreService.post('/v1/legacy/domain_model/del_property', payload).then(() => {
        const selectedId = this.selected_entity2.context.details.id
        this.$bvModal.hide('delete-property-modal')
        this.$store.dispatch('domainModel/selectEntity2', selectedId)
      })
    },

  },
}
</script>

<style lang="scss" scoped>

</style>
